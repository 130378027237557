import { db } from "./db.js";

const DashboardStorage = () => {
  const dashboardAdd = async (data) => {
    await db.dashboard.put(data);
  };

  const dashboardAll = async () => {
    return await db.dashboard.orderBy("id").last();
  };

  const dashboardDeleteById = async (id) => {
    await db.dashboard.delete(id);
  };

  const dashboardClear = async () => {
    await db.dashboard.clear();
  };

  return {
    dashboardAdd,
    dashboardAll,
    dashboardDeleteById,
    dashboardClear,
  };
};

export default DashboardStorage;
