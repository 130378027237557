<template>
  <div class="dashboard">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <div class="dashboard-title">Informe general</div>
        </v-col>
      </v-row>

      <div class="grid-dashboard">
        <div class="col-content">
          <v-row>
            <v-col
              cols="12"
              lg="3"
              v-for="(item, i) in listOrders.order"
              :key="i"
            >
              <v-card elevation="2" class="dashboar-card" :loading="loaderCard">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title">
                      {{ item.name }}</v-list-item-title
                    >

                    <v-list-item-subtitle class="subtitle">
                      {{ item.count }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div :class="backgroudColor(item.id)">
                      <v-icon>{{ item.icon }}</v-icon>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: 30px">
            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" lg="8">
                  <div class="dashboard-subtitle">Productos por año</div>
                </v-col>
                <v-col cols="12" lg="4">
                  <v-select
                    :items="listOrders.listYears"
                    item-text="_id"
                    item-value="_id"
                    label="Seleccionar año"
                    outlined
                    dense
                    v-model="filter.chartYear"
                    color="second"
                  ></v-select>
                </v-col>
              </v-row>

              <v-card>
                <div style="padding: 16px">
                  <bar-chart
                    :chartData="listProductsYears.data"
                    :chartLabels="listProductsYears.labels"
                  ></bar-chart>
                </div>
              </v-card>
            </v-col>

            <v-col cols="12" lg="6">
              <v-row>
                <v-col cols="12" lg="7">
                  <div class="dashboard-subtitle">Productos por mes</div>
                </v-col>
                <v-col cols="12" lg="5">
                  <v-menu
                    ref="menuMonth"
                    v-model="menuMonth"
                    :close-on-content-click="false"
                    :return-value.sync="filter.chartYearMonth"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.chartYearMonth"
                        label="Seleccionar mes"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        color="second"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.chartYearMonth"
                      type="month"
                      no-title
                      scrollable
                      color="second"
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="second" @click="menuMonth = false">
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="second"
                        @click="onChangeMonth(filter.chartYearMonth)"
                      >
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-card>
                <div style="padding: 16px">
                  <pie-chart
                    :chartData="listProductsMonths.data"
                    :chartLabels="listProductsMonths.labels"
                  ></pie-chart>
                </div>
              </v-card>
            </v-col>
          </v-row>

          <v-row style="margin-top: 30px">
            <v-col cols="12">
              <v-row>
                <v-col cols="12" lg="8">
                  <div class="dashboard-subtitle">Récord de vendedores</div>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-text-field
                    v-model="search"
                    label="Buscar..."
                    outlined
                    dense
                    color="second"
                    clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="2">
                  <v-menu
                    ref="menuSearchMonth"
                    v-model="menuSearchMonth"
                    :close-on-content-click="false"
                    :return-value.sync="filter.sellerYearMonth"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="filter.sellerYearMonth"
                        label="Seleccionar mes"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        outlined
                        dense
                        color="second"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="filter.sellerYearMonth"
                      type="month"
                      no-title
                      scrollable
                      color="second"
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="second"
                        @click="menuSearchMonth = false"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        text
                        color="second"
                        @click="onChangeSelletMonth(filter.sellerYearMonth)"
                      >
                        Aceptar
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>

              <v-card class="page-list">
                <v-data-table
                  :mobile-breakpoint="768"
                  :headers="tableHeaders"
                  :items="listSeller"
                  :search="search"
                  :loading="loaderTable"
                  :items-per-page="5"
                  :footer-props="{
                    itemsPerPageOptions: [5, 10],
                  }"
                  loading-text="Cargando... Por favor espere."
                >
                  <template v-slot:[`item.photoSeller`]="{ item }">
                    <img
                      class="profile"
                      :src="item.photoSeller | urlImage"
                      alt="profile"
                    />
                  </template>

                  <template v-slot:[`item.dniSeller`]="{ item }">
                    {{ item.dniSeller | unitMile }}
                  </template>

                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip bottom color="dark">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          @click="detailItem(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon> mdi-information </v-icon>
                        </v-btn>
                      </template>
                      <span>Detalles</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </div>

        <div class="col-sidebar">
          <v-row>
            <v-col cols="12" v-for="(item, i) in listOrders.user" :key="i">
              <v-card elevation="2" class="dashboar-card" :loading="loaderCard">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="title">
                      {{ item.name }}</v-list-item-title
                    >

                    <v-list-item-subtitle class="subtitle">
                      {{ item.count }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <div class="icon user">
                      <v-icon>{{ item.icon }}</v-icon>
                    </div>
                  </v-list-item-action>
                </v-list-item>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-container>

    <CustomDetails
      :dialog.sync="detailModal"
      :item="selectItem"
      :chart="true"
    />
  </div>
</template>

<script>
import useColumns from "./useColumns";
import DashboardServices from "../../../../core/services/dashboard-services";
import DashboardStorage from "../../../../core/storage/dashboard-storage";
import CustomDetails from "../../../../shared/components/custom-details/CustomDetails.vue";
import BarChart from "../../../../shared/components/custom-chart/BarChart.vue";
import PieChart from "../../../../shared/components/custom-chart/PieChart.vue";
import _ from "lodash";
import * as moment from "moment";

export default {
  name: "Dashboard",
  data: () => ({
    listOrders: {
      listProducts: [],
      listProductsMonth: [],
      listYears: [],
      order: [],
      user: [],
    },
    loaderCard: false,
    listProductsYears: {},
    listProductsMonths: {},
    listSeller: [],
    tableHeaders: useColumns,
    search: "",
    listData: [],
    loaderTable: false,
    detailModal: false,
    selectItem: {},
    menuMonth: false,
    menuSearchMonth: false,
    filter: {
      chartYear: null,
      chartMonth: null,
      chartMonthYear: null,
      chartYearMonth: null,
      sellerYearMonth: null,
      sellerYear: null,
      sellerMonth: null,
    },
  }),
  components: {
    BarChart,
    PieChart,
    CustomDetails,
  },
  created() {
    this.filter.chartYear = parseInt(moment().format("YYYY"));
    this.filter.chartYearMonth = moment().format("YYYY-MM");
    this.filter.chartMonthYear = parseInt(moment().format("YYYY"));
    this.filter.chartMonth = parseInt(moment().format("MM"));
    this.filter.sellerYearMonth = moment().format("YYYY-MM");
    this.filter.sellerYear = parseInt(moment().format("YYYY"));
    this.filter.sellerMonth = parseInt(moment().format("MM"));

    this.getDashboardAll();
    this.dashboardAllStorage();
    this.getListProductsYear();
    this.getListProductsMonths();
    this.getListSeller();
  },
  methods: {
    async dashboardAllStorage() {
      const { dashboardAll } = DashboardStorage();
      const listOrders = await dashboardAll();
      if (listOrders) {
        this.listOrders = await dashboardAll();
      }
    },
    getDashboardAll() {
      const { getDasboardAll } = DashboardServices();
      const { dashboardAdd } = DashboardStorage();
      this.loaderCard = true;
      return new Promise((resolve, reject) => {
        getDasboardAll(this.filter).then((resp) => {
          const response = resp.data;
          if (response.code === 200) {
            dashboardAdd(response.data);
            this.listOrders = response.data;
            this.dashboardAllStorage();
            this.getListProductsYear();
            this.getListProductsMonths();
            this.getListSeller();
            resolve(true);
          }

          this.loaderCard = false;
          reject(false);
        });
      });
    },
    backgroudColor(id) {
      switch (id) {
        case 1:
          return `icon pedido`;
        case 2:
          return `icon verificar`;
        case 3:
          return `icon completado`;
        case 4:
          return `icon eliminar`;
      }
    },
    async getListProductsYear() {
      this.listProductsYears = {};
      const { dashboardAll } = DashboardStorage();
      const list = await dashboardAll();

      const labels = [];
      const data = [];

      if (list) {
        list.listProductsMonth.map((item) => {
          labels.push(item.month);
          data.push(item.count || 0);
        });
      }

      this.listProductsYears = {
        labels,
        data,
      };
    },
    async getListProductsMonths() {
      this.listProductsMonths = {};
      const { dashboardAll } = DashboardStorage();
      const list = await dashboardAll();

      const labels = [];
      const data = [];

      if (list) {
        list.listProducts.map((item) => {
          labels.push(item._id.nameProduct);
          data.push(item.count || 0);
        });
      }

      this.listProductsMonths = {
        labels,
        data,
      };
    },
    async getListSeller() {
      this.listSeller = [];
      const { dashboardAll } = DashboardStorage();
      const list = await dashboardAll();
      const newList = [];
      if (list) {
        for (const key in list.listSeller) {
          list.listSeller[key].total = 0;
          list.listSeller[key].map((item) => {
            list.listSeller[key].total =
              list.listSeller[key].total + item.count;
          });
        }

        for (const key in list.listSeller) {
          newList.push({
            ...list.listSeller[key][0]._id,
            total: list.listSeller[key].total,
          });
        }
      }

      this.listSeller = _.orderBy(newList, ["total"], ["desc"]);
    },
    async detailItem(item) {
      const { dashboardAll } = DashboardStorage();
      const list = await dashboardAll();
      this.detailModal = true;
      const labels = [];
      const data = [];
      const selectItem = list.listSeller[item.idSeller];

      selectItem.map((item) => {
        labels.push(item._id.nameProduct);
        data.push(item.count || 0);
      });

      this.selectItem = {
        labels,
        data,
      };
    },
    onChangeMonth(value) {
      this.listProductsMonths = {};
      this.filter.chartMonthYear = parseInt(value.split("-")[0]);
      this.filter.chartMonth = parseInt(value.split("-")[1]);
      this.$refs.menuMonth.save(value);
      this.getDashboardAll().then((resp) => {
        if (resp) {
          this.getListProductsMonths();
        }
      });
    },
    onChangeSelletMonth(value) {
      this.listSeller = [];
      this.filter.sellerYear = parseInt(value.split("-")[0]);
      this.filter.sellerMonth = parseInt(value.split("-")[1]);
      this.$refs.menuSearchMonth.save(value);
      this.getDashboardAll().then((resp) => {
        if (resp) {
          this.getListSeller();
        }
      });
    },
  },
};
</script>

<style lang="scss">
.dashboard {
  .profile {
    width: 60px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    margin: 10px 0px;
  }

  .dashboar-card {
    padding: 10px 5px;
    border-radius: 10px;

    .title {
      font-size: 14px !important;
      font-weight: 500;
      font-family: poppins, "Roboto", sans-serif !important;
    }

    .subtitle {
      margin-top: 0.25rem;
      margin-bottom: 0.5rem;
      font-size: 2.2rem;
      font-weight: 500;
      font-family: poppins, "Roboto", sans-serif !important;
    }

    .icon {
      height: 50px;
      width: 50px;
      line-height: 50px;
      border-radius: 7px;
      text-align: center;
      font-size: 24px;

      i {
        color: #fff !important;
        font-size: 2rem;
      }

      &.pedido {
        background: #166a97;
      }

      &.verificar {
        background: #e8ae60;
      }

      &.completado {
        background: #72b666;
      }

      &.eliminar {
        background: #cb5d54;
      }

      &.user {
        background: #4579b2;
      }
    }
  }

  .dashboard-title {
    color: var(--color-info-dark);
    font-weight: 600;
    font-size: 1.8rem;
    padding: 10px 0px 0px;
  }

  .dashboard-subtitle {
    color: var(--color-info-dark);
    font-weight: 600;
    font-size: 1.5rem;
    padding: 10px 0px 0px;
  }

  .grid-dashboard {
    display: grid;
    grid-template-columns: 1fr 350px;
    column-gap: 15px;
    margin-top: 15px;

    .col-content {
      // padding-right: 16px;
    }

    .col-sidebar {
      padding: 0px 0px 0px 16px;
      border-left-width: 1px;
      border-style: solid;
      border-color: #e5e7eb;
    }
  }
}

@media only screen and (max-width: 1124px) {
  .dashboard {
    .grid-dashboard {
      grid-template-columns: 1fr;

      .col-sidebar {
        padding: 0px 0px 0px 0px;
        margin-top: 30px;
        border-left-width: 0px;
      }
    }
  }
}
</style>
