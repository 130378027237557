<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    chartData: {
      type: Array,
    },
    chartLabels: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
  },
  watch: {
    chartData: function (val) {
      if (val) {
        this.renderPieChart();
      }
    },
  },
  data() {
    return {
      options: {},
    };
  },
  mounted() {
    this.renderPieChart();
  },
  methods: {
    renderPieChart() {
      this.options = {
        responsive: true,
        title: {
          display: false,
          text: this.title,
          fontSize: 22,
        },
        legend: {
          position: "bottom",
          fontSize: 20,
        },
        maintainAspectRatio: false,
        elements: {
          line: {
            tension: 0.000001,
          },
        },
      };

      const chartData = {
        labels: this.chartLabels,
        datasets: [
          {
            label: "Total de productos",
            backgroundColor: "#166a97",
            data: this.chartData,
          },
        ],
      };

      this.renderChart(chartData, this.options);
    },
  },
};
</script>

<style lang="scss">
#bar-chart {
  width: 100% !important;
  height: 350px !important;
}
</style>
