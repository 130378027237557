import axios from "axios";

const DashboardServices = () => {
  const getDasboardAll = async (data) => {
    const filter = `/?chartYear=${data.chartYear}&chartMonthYear=${data.chartMonthYear}&chartMonth=${data.chartMonth}&sellerYear=${data.sellerYear}&sellerMonth=${data.sellerMonth}`;
    return await axios
      .get(`${process.env.VUE_APP_ROOT_API}/dashboard${filter}`)
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        return error.response || error;
      });
  };

  return {
    getDasboardAll,
  };
};

export default DashboardServices;
