const useColumns = [
  {
    text: "Foto",
    align: "start",
    value: "photoSeller",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Cédula",
    align: "start",
    value: "dniSeller",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Nombre",
    align: "start",
    value: "nameSeller",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Apellido",
    align: "start",
    value: "lastNameSeller",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Total de productos",
    align: "center",
    value: "total",
    class: "header-title",
    sortable: false,
  },
  {
    text: "Acción",
    value: "actions",
    sortable: false,
    class: "header-title",
  },
];

export default useColumns;
